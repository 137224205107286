<script setup>
import { ref } from "vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const { mdAndUp, lgAndUp, xlAndUp, xsOnly } = deviceBreakpoint();
const dashboardURL = ref(`${window.location.origin}/tenant`);

const display = ref(true);
const route = useRoute();
const router = useRouter();

const closePaymentModal = () => {
  display.value = false;
  router.push("/tenant");
};
</script>
<template>
  <div class="container-top" :class="{ 'container-wide': lgAndUp || xlAndUp }">
    <el-dialog
      title=""
      class="border-radius-20"
      v-model="display"
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : xsOnly ? `90%` : `70%`"
      align-center
      :modal="true"
      :show-close="false"
    >
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="s-18 font-weight-600 text-dak">Payment Status</div>
          <div>
            <el-button class="border-0" @click="closePaymentModal"
              ><el-icon :size="20"><Close /></el-icon
            ></el-button>
          </div>
        </div>
        <div
          class="col-md-12"
          v-if="route.fullPath.includes('payments/stripe/success')"
        >
          <div class="col-md-12 d-flex justify-content-center">
            <div class="col-md-8">
              <img
                class="w-100"
                src="../../assets/mobileonboarding/sub-successful.gif"
                alt=""
              />
            </div>
          </div>
          <div class="col-12 col-md-12 ">
            <h2 class="text-center mt-4  font-weight-bold text-dak">Congrats</h2>
            <div class="text-center mt-2 text-dak font-weight-600 s-18">
              Your payment is Successful <br />Click the button below to go to
              the dashboard
            </div>
            <div class="d-flex justify-content-center mb-5">
              <a :href="dashboardURL" class="no-decoration">
                <el-button color="#70c043" class="text-white mt-3" round
                  >Go to dashboard</el-button
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
