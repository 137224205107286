<script setup>
import { ref, inject } from "vue";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
const primarycolor = inject("primarycolor");

const { mdAndUp, lgAndUp, xlAndUp, xsOnly } = deviceBreakpoint();
// const dashboardURL = ref(`${window.location.origin}/tenant`);

const paymentFailed = ref(true);
const route = useRoute();
const router = useRouter();

const closePaymentModal = () => {
  paymentFailed.value = false;
  router.push("/tenant");
};

const tryAgain = () => {
  paymentFailed.value = false;
  router.push("/tenant/subscription");
};
</script>
<template>
  <div class="container-top" :class="{ 'container-wide': lgAndUp || xlAndUp }">
    <el-dialog
      v-if="route.fullPath.includes('payments/stripe/canceled')"
      class=""
      style="border-radius: 25px"
      v-model="paymentFailed"
      title=""
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
      :show-close="false"
    >
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="s-18 font-weight-600 text-dak">Payment Canceled</div>
          <div>
            <el-button class="border-0" @click="closePaymentModal"
              ><el-icon :size="20"><Close /></el-icon
            ></el-button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-10 mt-4 h-100 bg-white mb-5">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-10 d-flex justify-content-center">
              <div class="col-md-6">
                <img
                  class="w-100"
                  src="../../assets/mobileonboarding/sub-failed.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
              <div class="col-md-6 col-8">
                <div
                  class="text-head font-weight-bold col-md-12 px-0 h2 text-center"
                  style="color: #d21615"
                >
                   Payment Canceled!
                </div>
              </div>
            </div>
            <div class="col-md-10 d-flex my-5 justify-content-center">
              <div class="col-md-8">
                <el-button
                  @click="tryAgain"
                  :color="primarycolor"
                  size="large"
                  class="w-100"
                  round
                  >Try again</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
